<template>
  <b-modal centered v-model="modal" size="lg" hide-footer>
    <div class="my-2">
      <div>
        <p class="fw-bolder">
          {{ typeform._id ? $t('helpers.update') : $t('helpers.add') }} {{ $t('faq.formTitle') }}
        </p>
      </div>
      <div>
        <p>{{ $t('faq.question') }}:</p>
        <vs-input placeholder="Pregunta" v-model="typeform.question" class="shadow-lg mb-3" :class="{
          'is-invalid': $v.typeform.question.$error,
        }" />
        <div v-if="$v.typeform.question.$error" class="invalid-feedback">
          <span v-if="!$v.typeform.question.required">
            Este campo es requerido
          </span>
        </div>
        <p>{{ $t('faq.answer') }}:</p>
        <b-form-textarea placeholder="Respuesta" class="border-0 shadow-lg py-3 px-4" rows="6" max-rows="6" no-resize
          v-model="typeform.answer" :class="{
            'is-invalid': $v.typeform.answer.$error,
          }"></b-form-textarea>
        <div v-if="$v.typeform.answer.$error" class="invalid-feedback">
          <span v-if="!$v.typeform.answer.required">
            Este campo es requerido
          </span>
        </div>
      </div>
      <vs-button class="mt-3" @click="save" v-if="!typeform._id">{{ $t('form.save') }}</vs-button>
      <vs-button class="mt-3" @click="update" v-else>{{ $t('helpers.update') }}</vs-button>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["typeform"],
  data() {
    return {
      modal: false,
    };
  },
  validations: {
    typeform: {
      question: {
        required,
      },
      answer: {
        required,
      },
    },
  },
  methods: {
    async save() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const { data } = await this.$http.post("/faqs", this.typeform);

      if (!data.ok) {
        return Swal.fire("Error guardando F.A.Q");
      }

      Swal.fire("F.A.Q almacenado", "", "success");

      return this.$emit("closeModal");
    },
    async update() {
      const id = this.typeform._id;

      const { data } = await this.$http.put(`/faqs/${id}`, this.typeform);
      if (!data.ok) {
        return Swal.fire("Error actualizando F.A.Q", "", "info");
      }

      Swal.fire("F.A.Q actualizado", "", "success");

      return this.$emit("closeModal");
    },
  },
};
</script>

<style></style>
